import React, { useEffect, useState } from "react";
import { Input, Select, Modal, Row, Form, Col, Spin } from "antd";
import APIService from "../../../services/api";

const traumaLevelOptions = [
  {
    value: 1,
    label: "Level 1",
  },
  {
    value: 2,
    label: "Level 2",
  },
  {
    value: 3,
    label: "Level 3",
  },
  {
    value: 4,
    label: "Level 4",
  },
  {
    value: 5,
    label: "Level 5",
  },
];

const CreateErDirectory = ({ modalData, setModalData }) => {
  const [userform] = Form.useForm();
  const [organizationList, setOrganizationList] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [organizationId, setOrganizationId] = React.useState("");
  const [traumaLevel, setTraumaLevel] = useState(1);
  const [isDoctor, setIsDoctor] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setLoading(true);
    getOrganizationsData();
    if (modalData.data && Object.keys(modalData.data).length) {
      setInitialValues({
        ...modalData.data.profile,
        name: modalData.data?.name || "",
        addressLine1: modalData.data?.address?.addressLine1 || "",
        addressLine2: modalData.data?.address?.addressLine2 || "",
        city: modalData.data?.address?.city || "",
        state: modalData.data?.address?.state || "",
        zipCode: modalData.data?.address?.zipCode || "",
        traumaLevel: modalData.data?.traumaLevel || "",
        organizationId: modalData.data?.organizationId || "",
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (initialValues) {
      setLoading(false);
    }
  }, [initialValues]);

  const onFinish = (values) => {
    try {
      setLoading(true);
      let requestObj = {
        name: values?.name || "",
        address: {
          addressLine1: values?.addressLine1 || "",
          addressLine2: values?.addressLine2 || "",
          city: values?.city || "",
          state: values?.state || "",
          zipCode: values?.zipCode || "",
        },
        traumaLevel: values?.traumaLevel || "",
        organizationId: values?.organizationId || "",
      };
      if (modalData?.data?._id) {
        APIService.requestERDirectoryWithToken(
          "emergency-room/update/" + modalData.data._id,
          "put",
          requestObj
        ).then(({ res }) => {
          if (res && res.code === "OK") {
            setModalData({ visible: false, success: true });
            setLoading(false);
          }
        });
      } else {
        APIService.requestERDirectoryWithToken(
          "emergency-room/add",
          "post",
          requestObj
        ).then(({ res }) => {
          if (res && res.code === "OK") {
            setModalData({ visible: false, success: true });
            setLoading(false);
          }
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setModalData({ visible: false });
  };

  const debounceFetcher = (searchStr) => {
    setFetching(true);
    let searchQuery = "";
    if (searchStr) {
      searchQuery = { name: { $regex: searchStr, $options: "i" } };
    }
    getOrganizationsData(searchQuery);
  };

  const getOrganizationsData = (searchQuery) => {
    APIService.requestWithToken("api/v1/organization", "get", {
      query: searchQuery
        ? JSON.stringify(searchQuery)
        : '{"$or": [{ "deactivated": false }, { "deactivated": { "$exists": false } }] }',
      select: JSON.stringify(["_id", "name", "deactivated"]),
      sort: JSON.stringify({ name: 1, createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        setFetching(false);
        let orgList = res.data.list.map((a) => {
          return {
            label: a.name,
            value: a._id,
          };
        });
        setOrganizationList(orgList);
      }
    });
  };

  return (
    <Modal
      width={800}
      title={modalData?.data?._id ? "Update ER" : "Add ER"}
      visible={modalData?.visible}
      okText="Submit"
      cancelText="Cancel"
      onOk={() => {
        userform
          .validateFields()
          .then((values) => {
            userform.resetFields();
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
    >
      {!loading ? (
        <Form
          form={userform}
          initialValues={initialValues}
          name="control-hooks"
          layout="vertical"
          onFinish={onFinish}
        >
          <Row justify="space-between">
            <Col xs={7} md={7}>
              <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Name" />
              </Form.Item>
            </Col>

            <Col xs={7} md={7}>
              <Form.Item
                name="traumaLevel"
                label="Trauma Level"
                rules={[
                  { required: true, message: "Trauma Level is required." },
                ]}
              >
                <Select
                  placeholder="Select trauma level"
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  value={traumaLevel}
                  onChange={(newValue) => {
                    setTraumaLevel(newValue);
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={traumaLevelOptions}
                />
              </Form.Item>
            </Col>

            <Col xs={7} md={7}>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  { required: false, message: "Phone no is required." },
                  {
                    min: 10,
                    message: "Please enter valid Phone Number!",
                  },
                  {
                    max: 10,
                    message: "Please enter valid Phone Number!",
                  },
                ]}
              >
                <Input placeholder="Phone Number" />
              </Form.Item>
            </Col>
            <Col xs={7} md={11}>
              <Form.Item
                name="addressLine1"
                label="Address Line 1"
                rules={[
                  { required: true, message: "Address Line 1 is required." },
                ]}
              >
                <Input placeholder="Address Line 1" />
              </Form.Item>
            </Col>
            <Col xs={7} md={11}>
              <Form.Item name="addressLine2" label="Address Line 2">
                <Input placeholder="Address Line 2" />
              </Form.Item>
            </Col>
            <Col xs={7} md={7}>
              <Form.Item
                name="city"
                label="City"
                rules={[{ required: true, message: "City is required." }]}
              >
                <Input placeholder="City" type="city" />
              </Form.Item>
            </Col>

            <Col xs={7} md={7}>
              <Form.Item
                name="state"
                label="State"
                rules={[{ required: true, message: "State is required." }]}
              >
                <Input placeholder="State" type="state" />
              </Form.Item>
            </Col>

            <Col xs={7} md={7}>
              <Form.Item
                name="zipCode"
                label="Zip Code"
                rules={[{ required: true, message: "Zip Code is required." }]}
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>

            <Col xs={7} md={16}>
              <Form.Item
                name="organizationId"
                label="Organizations"
                rules={[
                  { required: true, message: "Organizations is required." },
                ]}
              >
                <Select
                  placeholder="Select organizations"
                  filterOption={false}
                  showSearch
                  onSearch={(value) => debounceFetcher(value)}
                  notFoundContent={fetching ? <Spin size="small" /> : null}
                  value={organizationId}
                  onChange={(newValue) => {
                    setOrganizationId(newValue);
                  }}
                  style={{
                    width: "100%",
                  }}
                  options={organizationList}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Spin />
      )}
    </Modal>
  );
};

export default CreateErDirectory;
