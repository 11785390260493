import React, { useState, useEffect } from 'react';
import { Form, Select, Input, Button, InputNumber, message, Spin } from 'antd';
import APIService from "../../services/api";

const EHR = ({ organizationId }) => {
  const [selectedEHR, setSelectedEHR] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const ehrOptions = [
    { label: 'Athenahealth', value: 'athenahealth' },
    { label: 'ModMed', value: 'modmed' },
  ];

  useEffect(() => {
    const fetchEhrConfig = async () => {
      setLoading(true);
      try {
        const apiResponse = await APIService.requestWithToken(
          `api/v1/organization/config/${organizationId}`,
          "get"
        );
        const response = apiResponse.res;
        if (response.code === 'OK') {
          const ehrConfig = response.data.ehrConfig;
          if (ehrConfig) {
            const ehrType = Object.keys(ehrConfig)[0];
            setSelectedEHR(ehrType);
            const config = ehrConfig[ehrType];
            form.setFieldsValue({
              ehrType,
              ...config,
              duration: config.defaultAppointmentType?.duration,
              appointmentTypeId: config.defaultAppointmentType?.id,
              appointmentTypeName: config.defaultAppointmentType?.name,
            });
          }
        } else {
          message.error('Failed to fetch EHR configuration');
        }
      } catch (error) {
        console.error('Error fetching EHR config:', error);
        message.error('An error occurred while fetching EHR configuration');
      } finally {
        setLoading(false);
      }
    };

    fetchEhrConfig();
  }, [organizationId, form]);

  const onFinish = async (values) => {
    const ehrConfig = {
      [values.ehrType]: {
        ...(values.ehrType === 'athenahealth'
          ? {
              departmentId: values.departmentId,
              practiceId: values.practiceId,
              authToken: values.authToken,
              defaultAppointmentType: {
                duration: values.duration,
                id: values.appointmentTypeId,
                name: values.appointmentTypeName,
              },
            }
          : {
              practiceId: values.practiceId,
              locationId: values.locationId,
              apiKey: values.apiKey,
              username: values.username,
              password: values.password,
              defaultAppointmentType: {
                duration: values.duration,
                id: values.appointmentTypeId,
                name: values.appointmentTypeName,
              },
            }),
      },
    };
    try {
      const requestBody = {
        organizationId,
        ehrConfig,
      };
      console.log('requestBody:', requestBody);
      const response = await APIService.requestWithToken(
        "api/v1/organization/ehrConfig",
        "put",
        requestBody
      );
      console.log("putresponse", response);
      if (response?.res?.code === "OK") {
        message.success('EHR configuration saved successfully');
      } else {
        message.error('Failed to save EHR configuration');
      }
    } catch (error) {
      console.error('Error saving EHR config:', error);
      message.error('An error occurred while saving EHR configuration');
    }
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="ehrType"
          label="EHR Type"
          rules={[{ required: true, message: 'Please select EHR type' }]}
        >
          <Select
            options={ehrOptions}
            onChange={(value) => {
              setSelectedEHR(value);
              form.resetFields();
              form.setFieldsValue({ ehrType: value });
            }}
            placeholder="Select EHR type"
          />
        </Form.Item>

        {selectedEHR === 'athenahealth' && (
          <>
            <Form.Item
              name="departmentId"
              label="Department ID"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="practiceId"
              label="Practice ID"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="authToken"
              label="Auth Token"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </>
        )}

        {selectedEHR === 'modmed' && (
          <>
            <Form.Item
              name="practiceId"
              label="Practice ID"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="locationId"
              label="Location ID"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="apiKey"
              label="API Key"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>
          </>
        )}

        {selectedEHR && (
          <>
            <h4>Default Appointment Type</h4>
            <Form.Item
              name="duration"
              label="Duration"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="appointmentTypeId"
              label="Appointment Type ID"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="appointmentTypeName"
              label="Appointment Type Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default EHR;