import { Button, Tabs, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { StepBackwardOutlined } from '@ant-design/icons'

import EpicTab from './EpicTab'
import ServicesTab from './Services'
import APIService from '../../services/api'
import HospitalChatBotTab from './HospitalChatBotTab'
import UploadProviderTab from './UploadProviderTab'
import EditOrganizationTab from './EditOrganizationTab'
import NewConsultTimeSlotsDifferenceTab from './NewConsultTimeSlotsDifferenceTab'
import AthenaConfig from './athenaConfig'
// import PhysicianDirectoryMaintenance from "./PhysicianDirectoryMaintenance"
import Configuration from './Configuration'
import EHR from './EHR'
import IntegrationOutboundTab from './IntegrationOutboundTab'
import UnifiedHospitals from './UnifiedHospitals'
import MeditechConfig from './MeditechConfig'
import CronJob from './CronJob.js'
import IVRConfig from './IVRConfig.js'

const { Title } = Typography
const { TabPane } = Tabs

export const Setting = () => {
  const { id } = useParams()
  const history = useHistory()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [departmentsData, setDepartmentsData] = useState([])

  useEffect(() => {
    setLoading(true)
    APIService.requestWithToken(`api/v1/admin/org/${id}`, 'get')
      .then((res) => {
        let orgData = {}
        if (res.res.data.orgData) {
          orgData = {
            ...res.res.data.orgData,
            practiceId: res.res.data.orgData?.athenaConfig?.practiceId,
            departmentId: res.res.data.orgData?.athenaConfig?.departmentId,
          }
          setData(orgData)
        }
        APIService.requestWithToken(
          `api/v1/admin/department/fetch-departments`,
          'get'
        ).then((res) => {
          setDepartmentsData(res.res.data)
          setLoading(false)
        })
      })
      .finally(() => setLoading(false))
  }, [id])

  return (
    <>
      <div className='setting-main'>
        <div className='df  am'>
          <Title level={3} style={{ flex: 1 }}>
            {' '}
            Settings{' '}
          </Title>
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Button
            type='primary'
            onClick={() => history.push(`/app/organizations`)}
          >
            <StepBackwardOutlined />
            Back
          </Button>
        </div>
      </div>
      <div>
        <Tabs defaultActiveKey='1'>
          <TabPane tab='General' key='1'>
            {!loading && (
              <EditOrganizationTab
                id={id}
                data={data}
                departmentsData={departmentsData}
              />
            )}
          </TabPane>
          <TabPane tab='Services' key='2'>
            {!loading && <ServicesTab id={id} data={data} />}
          </TabPane>
          <TabPane tab='EHR' key='3'>
            {!loading && <EHR organizationId={id} data={data} />}
          </TabPane>
          <TabPane tab='EHR Outbound' key='10'>
            {!loading && <IntegrationOutboundTab id={id} data={data} />}
          </TabPane>
          <TabPane tab='Internal Data Sync' key='12'>
            {!loading && <CronJob id={id} data={data} />}
          </TabPane>
          <TabPane tab='Epic' key='4'>
            {!loading && <EpicTab id={id} data={data} />}
          </TabPane>
          <TabPane tab='Hospital Chat Bot' key='5'>
            {!loading && <HospitalChatBotTab id={id} data={data} />}
          </TabPane>
          <TabPane tab='IVR Config' key='6'>
            {!loading && <IVRConfig id={id} data={data} />}
          </TabPane>
          <TabPane tab='New Consult Time Slots Difference' key='7'>
            {!loading && (
              <NewConsultTimeSlotsDifferenceTab id={id} data={data} />
            )}
          </TabPane>
          <TabPane tab='Upload Provider' key='8'>
            <UploadProviderTab id={id} data={data} />
          </TabPane>
          {/* <TabPane tab="Physician Directory Maintenance" key="7">
            <PhysicianDirectoryMaintenance id={id} data={data} />
          </TabPane> */}
          <TabPane tab='Concierge' key='9'>
            {!loading && <Configuration id={id} data={data} />}
          </TabPane>
          <TabPane tab='Unified Hospital' key='11'>
            {!loading && <UnifiedHospitals id={id} data={data} />}
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}
