import React from 'react';
import { Button, Col, Form, Checkbox, notification, Row } from 'antd';
import StorageService from '../../services/storage';
import APIService from '../../services/api';
import { Tabs } from "antd"
const { TabPane } = Tabs

const Services = ({ id, data }) => {
    const [form] = Form.useForm();

    const openNotification = () => {
        notification.open({
            message: 'Updated Successfully',
        });
    };

    const onFinish = (values) => {
        let userData = StorageService.getData('user');
        let requestObj = {
            services: values,
            userId: userData._id,
            organizationId: id,
            organizationName: data?.name,
            organizationType: data?.organizationType,
        };

        APIService.requestWithToken(
            'api/v1/organization/addOrEditOrganization',
            'post',
            requestObj
        )
            .then((response) => {
                if (response?.res?.message.includes('organization update successfully.')) {
                    openNotification();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const defaultServices = {
        web: false,
        onlineAppointmentRequest: false,
        chatbot: false,
        ivr: false,
        reminders: false,
        surveys: false,
        referrals: false,
        integration: false,
        ehrScheduling: false,
        digitizedIntake: false,
        dfd: false,
        campaigns: false,
        campaignSurveys: false,
        paymentCollection: false,
        gmb: false,
        px: false,
        providerProfiles: false,
        telemedicine: false,
        steerVoice: false,
        steerNotes: false,
        onlineEreg: false,
        ehrIntegration: false,
        ehrOutbound: false,
        kiosk: false,
        virtualCheckin: false,
        awv: false,
        twoWaySMS: false,
        concierge: false,
        getCareNow: false
    };

    const existingServices = data?.services || {};
    const services = { ...defaultServices, ...existingServices };

    const formatFieldName = (name) => {
        return name
            .split(/(?=[A-Z])/)
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const renderCheckboxes = () => {
        return Object.entries(services).map(([key, value], index) => (
            <Col xs={24} sm={12} key={key}>
                <Form.Item
                    name={key}
                    valuePropName="checked"
                    initialValue={value}
                >
                    <Checkbox>{formatFieldName(key)}</Checkbox>
                </Form.Item>
            </Col>
        ));
    };

    return (
        <>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Services" key="1">
                    <Form
                        form={form}
                        initialValues={services}
                        name="control-hooks"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Row gutter={[16, 16]}>
                            {renderCheckboxes()}
                        </Row>
                        <Form.Item
                            wrapperCol={{
                                offset: 11,
                                span: 16,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        </>
    );
};

export default Services;