import React, { useState } from 'react';
import { Form, Input, Button, Table, DatePicker, Select, Card, Row, Col, message, Tabs } from 'antd';
import APIService from "../../services/api";
import moment from 'moment';


const { TabPane } = Tabs;
const { Option } = Select;

function IndexPage() {
  const [form] = Form.useForm();
  const [apptForm] = Form.useForm();
  const [patientData, setPatientData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const patientColumns = [
    { title: 'Row ID', dataIndex: 'rowid', key: 'rowid' },
    { title: 'Patient ID', dataIndex: 'ptid', key: 'ptid' },
    { title: 'First Name', dataIndex: 'ptfname', key: 'ptfname' },
    { title: 'Last Name', dataIndex: 'ptlname', key: 'ptlname' },
    { title: 'DOB', dataIndex: 'ptdob', key: 'ptdob' },
    { title: 'City', dataIndex: 'ptcity', key: 'ptcity' },
    { title: 'State', dataIndex: 'ptstate', key: 'ptstate' },
    { title: 'Phone', dataIndex: 'phonenumber', key: 'phonenumber' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Source EHR', dataIndex: 'sourceehr', key: 'sourceehr' },
    { title: 'Last Updated', dataIndex: 'last_upd_timestamp', key: 'last_upd_timestamp' },
  ];

  const appointmentColumns = [
    { title: 'Row ID', dataIndex: 'rowid', key: 'rowid' },
    { title: 'Patient ID', dataIndex: 'ptid', key: 'ptid' },
    { title: 'Appointment ID', dataIndex: 'apptid', key: 'apptid' },
    { title: 'Type', dataIndex: 'appttype', key: 'appttype' },
    { title: 'Status', dataIndex: 'apptstatus', key: 'apptstatus' },
    { title: 'Start Time', dataIndex: 'apptstarttime', key: 'apptstarttime' },
    { title: 'End Time', dataIndex: 'apptendtime', key: 'apptendtime' },
    { title: 'Duration', dataIndex: 'duration', key: 'duration' },
    { title: 'Reason', dataIndex: 'apptreason', key: 'apptreason' },
    { title: 'Source EHR', dataIndex: 'sourceehr', key: 'sourceehr' },
    { title: 'Provider NPI', dataIndex: 'providernpi', key: 'providernpi' },
    { title: 'Last Updated', dataIndex: 'last_upd_timestamp', key: 'last_upd_timestamp' },
  ];

  const sourceEhrOptions = [
    { id: 1, name: 'DrChrono', value: 'drchrono' },
    { id: 2, name: 'Athena', value: 'athena' },
    { id: 3, name: 'ECW', value: 'ecw' },
    { id: 4, name: 'NextGen', value: 'nextgen' },
    { id: 5, name: 'Aprima', value: 'aprima' },
    { id: 6, name: 'Epic', value: 'epic' },
    { id: 7, name: 'CPSI', value: 'cpsi' },
  ];

  const onPatientSearch = async (values) => {
    setLoading(true);
    try {
      if (values.ptdob) {
        values.ptdob = moment(values.ptdob).format('YYYY-MM-DD');
      }
      Object.keys(values).forEach((key) => values[key] === undefined && delete values[key]);
      const response = await APIService.requestWithToken('api/v1/integration/patient-search', 'POST', values);
      if (response?.res?.data && Array.isArray(response.res.data)) {
        const adjustedData = adjustDataTimestamps(response.res.data);
        setPatientData(adjustedData);
      } else {
        message.warning('No patient data found or invalid response format');
      }
    } catch (error) {
      console.error('Search error:', error);
      message.error(`Failed to fetch patient data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const onAppointmentSearch = async (values) => {
    setLoading(true);
    try {
      if (values.apptstarttime) {
        values.apptstarttime = moment(values.apptstarttime).format('YYYY-MM-DD HH:mm:ss');
      }
      if (values.apptendtime) {
        values.apptendtime = moment(values.apptendtime).format('YYYY-MM-DD HH:mm:ss');
      }
      Object.keys(values).forEach((key) => values[key] === undefined && delete values[key]);
      const response = await APIService.requestWithToken('api/v1/integration/appointment-search', 'POST', values);
      if (response?.res?.data && Array.isArray(response.res.data)) {
        const adjustedData = adjustDataTimestamps(response.res.data);
        setAppointmentData(adjustedData);
      } else {
        message.warning('No appointment data found or invalid response format');
      }
    } catch (error) {
      console.error('Appointment search error:', error);
      message.error(`Failed to fetch appointment data: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '24px' }}>
      <Card>
        <Tabs defaultActiveKey="1">
        <TabPane tab="Patient Search" key="1">
            <Form form={form} name="patient_search" onFinish={onPatientSearch} layout="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item name="ptid" label="Patient ID">
                    <Input placeholder="Enter Patient ID" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ptfname" label="First Name">
                    <Input placeholder="Enter First Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ptlname" label="Last Name">
                    <Input placeholder="Enter Last Name" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ptdob" label="DOB">
                    <DatePicker format="YYYY-MM-DD" placeholder="Select DOB" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ptcity" label="City">
                    <Input placeholder="Enter City" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ptstate" label="State">
                    <Input placeholder="Enter State" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="phonenumber" label="Phone Number">
                    <Input placeholder="Enter Phone Number" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="email" label="Email">
                    <Input placeholder="Enter Email" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sourceehr" label="Source EHR">
                    <Select placeholder="Select Source EHR" allowClear>
                      {sourceEhrOptions.map((option) => (
                        <Option key={option.id} value={option.value}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Button type="primary" htmlType="submit" loading={loading}>
                Search
              </Button>
            </Form>
            <Table
              columns={patientColumns}
              dataSource={patientData}
              loading={loading}
              rowKey="rowid"
              style={{ marginTop: '20px' }}
            />
          </TabPane>
          <TabPane tab="Appointment Search" key="2">
            {/* Appointment Search Form */}
            <Form form={apptForm} name="appointment_search" onFinish={onAppointmentSearch} layout="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item name="apptid" label="Appointment ID">
                    <Input placeholder="Enter Appointment ID" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="ptid" label="Patient ID">
                    <Input placeholder="Enter Patient ID" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="appttype" label="Appointment Type">
                    <Input placeholder="Enter Appointment Type" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="apptstatus" label="Appointment Status">
                    <Input placeholder="Enter Appointment Status" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="apptstarttime" label="Start Time">
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Select Start Time" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="apptendtime" label="End Time">
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" placeholder="Select End Time" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="duration" label="Duration">
                    <Input placeholder="Enter Duration" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="apptreason" label="Reason">
                    <Input placeholder="Enter Appointment Reason" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sourceehr" label="Source EHR">
                    <Select placeholder="Select Source EHR" allowClear>
                      {sourceEhrOptions.map((option) => (
                        <Option key={option.id} value={option.value}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="providernpi" label="Provider NPI">
                    <Input placeholder="Enter Provider NPI" />
                  </Form.Item>
                </Col>
                {/* Add other appointment search fields as needed */}
              </Row>
              <Button type="primary" htmlType="submit" loading={loading}>
                Search
              </Button>
            </Form>
            {/* Appointment Data Table */}
            <Table
              columns={appointmentColumns}
              dataSource={appointmentData}
              loading={loading}
              rowKey="rowid"
              style={{ marginTop: '20px' }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}

const adjustDataTimestamps = (dataArray) => {
  return dataArray.map((item) => {
    const adjustedItem = { ...item };

    // Adjust last_upd_timestamp
    if (adjustedItem.last_upd_timestamp && typeof adjustedItem.last_upd_timestamp === 'object') {
      adjustedItem.last_upd_timestamp = adjustedItem.last_upd_timestamp.value || adjustedItem.last_upd_timestamp;
    }

    // Adjust other date fields if necessary
    if (adjustedItem.ptdob && typeof adjustedItem.ptdob === 'object') {
      adjustedItem.ptdob = adjustedItem.ptdob.value || adjustedItem.ptdob;
    }
    if (adjustedItem.apptstarttime && typeof adjustedItem.apptstarttime === 'object') {
      adjustedItem.apptstarttime = adjustedItem.apptstarttime.value || adjustedItem.apptstarttime;
    }
    if (adjustedItem.apptendtime && typeof adjustedItem.apptendtime === 'object') {
      adjustedItem.apptendtime = adjustedItem.apptendtime.value || adjustedItem.apptendtime;
    }

    return adjustedItem;
  });
};

export default IndexPage;