import React, { useState } from "react"
import { Button, Checkbox, Form, notification } from "antd"

import APIService from "../../services/api"
import StorageService from "../../services/storage"

const CronJob = ({ id, data }) => {
  const { cronJobs } = data
  const [form] = Form.useForm()
  const [dataSync, setDataSync] = useState(
    cronJobs?.dataSync ? cronJobs?.dataSync : false
  )
  const [globalRequests, setGlobalRequests] = useState(
    cronJobs?.globalRequests ? cronJobs?.globalRequests : false
  )

  const [ enableWaitlist, setEnableWaitlist ] = useState(
    cronJobs?.waitlists?.enabled ? cronJobs?.waitlists?.enabled : false
  )

  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    })
  }
  const onFinish = () => {
    form
      .validateFields()
      .then(async () => {
        let userData = StorageService.getData("user")
        let requestObj = {
          userId: userData?._id,
          organizationId: id,
          organizationName: data?.name,
          organizationType: data?.organizationType,
          cronJobs: {
            dataSync: dataSync,
            globalRequests: globalRequests,
            waitlists :{
              enabled: enableWaitlist
            }
          },
        }
        const response = await APIService.requestWithToken(
          "api/v1/organization/addOrEditOrganization",
          "post",
          requestObj
        )

        if (
          response?.res?.message.includes("organization update successfully.")
        ) {
          openNotification()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onChange = (e) => {
    setDataSync(e.target.checked)
  }
  const onChangeGlobalRequests = (e) => {
    setGlobalRequests(e.target.checked)
  }
  const onChangeWaitlist = (e) => {
    setEnableWaitlist(e.target.checked)
  }

  console.log(data,"ehrConfig")

  return (
    <>
      <Form
        form={form}
        initialValues={data}
        name="control-hooks"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item name="name" label="" rules={[{ required: true }]}>
          <Checkbox checked={dataSync} onChange={onChange}>
          <b>Data Sync</b>
          <br></br>
          <i>Automates appointment status updates to Completed. Used for PV-Surveys.</i>
          </Checkbox>
        </Form.Item>
        <Form.Item name="name" label="" rules={[{ required: true }]}>
          <Checkbox checked={globalRequests} onChange={onChangeGlobalRequests}>
          <b>Global Requests</b>
          <br></br>
          <i>Automates resolving appointment requests into appointments, triggering EHR outbound if configured.</i>
          </Checkbox>
        </Form.Item>
        <Form.Item name="name" label="" rules={[{ required: true }]}>
          <Checkbox checked={enableWaitlist} onChange={onChangeWaitlist}>
          <b>Enable WaitList</b>
          <br></br>
          <i>Enables waitlist</i>
          </Checkbox>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 11,
            span: 16,
          }}
        >
          <div>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default CronJob
