import React, { useEffect, useState } from "react";
import { Table, Input, Space, Typography, Select, Button } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import APIService from "../../../services/api";
import CreateErDirectory from "../create";

const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;

const ERListing = () => {
  const history = useHistory();
  const [searchQuery, updateSearchQuery] = useState(undefined);
  const scrollHeight = window.innerHeight - 337;
  const [modalData, setModalData] = useState({
    visible: false,
    data: {},
    success: false,
  });
  const [tableData, updateTableData] = useState({
    loading: false,
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  useEffect(() => {
    getData(tableData.pagination);
  }, [searchQuery]);

  useEffect(() => {
    if (modalData.success) {
      getData(tableData.pagination);
      setModalData({
        visible: false,
        data: {},
        success: false,
      });
    }
  }, [modalData.success]);

  const getData = (pagination) => {
    updateTableData({
      ...tableData,
      pagination: {
        ...pagination,
      },
      loading: true,
      dataSource: [],
    });
    let query = {};
    if (searchQuery) {
      query = { ...searchQuery };
    }

    APIService.requestERDirectoryWithToken("emergency-room/list", "get", {
      pageNo: parseInt(pagination.current),
      limit: pagination.pageSize,
      query: query ? JSON.stringify(query) : null,
      sort: JSON.stringify({ createdAt: -1 }),
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        updateTableData({
          loading: false,
          dataSource: res.data.list,
          pagination: {
            ...pagination,
            total: res.data.count,
          },
        });
      }
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    // {
    //   title: "Doctors",
    //   dataIndex: "doctorIds",
    //   render: (text) => {
    //     return text.length;
    //   },
    // },
    // {
    //   title: "Time Zone",
    //   dataIndex: "timeZone",
    // },
    // {
    //   title: "Address",
    //   render: (record) => {
    //     return (
    //       record.street +
    //       ", " +
    //       record.city +
    //       ", " +
    //       record.state +
    //       ", " +
    //       record.zipCode
    //     );
    //   },
    // },
    {
      title: "Actions",
      width: 100,
      align: "center",
      render: (obj) => {
        return (
          <Space>
            <EditTwoTone
              onClick={() =>
                setModalData({
                  ...modalData,
                  visible: true,
                  data: obj,
                })
              }
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <div className="df  am">
        <Title level={3} style={{ flex: 1 }}>
          Emergency Rooms
        </Title>
        <Space>
          <Space>
            <Search
              placeholder="Search by ER name"
              onSearch={(searchStr) => {
                updateSearchQuery({
                  name: { $regex: searchStr, $options: "i" },
                });
              }}
              style={{ width: 200 }}
            />
          </Space>
        </Space>
        <Button
          type="primary"
          onClick={() => {
            setModalData({ ...modalData, visible: true });
          }}
        >
          Add
        </Button>
      </div>

      <Table
        {...tableData}
        columns={columns}
        onChange={getData}
        scroll={{ y: scrollHeight }}
      ></Table>

      {modalData.visible ? (
        <CreateErDirectory
          modalData={modalData}
          setModalData={setModalData}
        ></CreateErDirectory>
      ) : null}
    </div>
  );
};

export default ERListing;
