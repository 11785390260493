import React from "react";
import {
  NotificationOutlined,
  UserOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";
export const PRIMARY_COLOR = "#1DA57A";
export const DANGER_COLOR = "#ff4d4f";
export const SUCCESS_COLOR = "#52c41a";
export const INFO_COLOR = "#1890ff";
export const MOBILE_NUMBER_REGEX = /^\d{10}$/g;

const moment = require("moment-timezone");

export let timeZone = moment.tz.names();

let offsetTmz = [];
for (var i in timeZone) {
  offsetTmz.push(
    " (GMT" + moment.tz(timeZone[i]).format("Z") + ") " + timeZone[i]
  );
}

export const MENU = [
  {
    id: 1,
    label: "User Management",
    link: "/app/user-management",
    icon: <UserOutlined />,
  },
  {
    id: 2,
    label: "Patients Notifications",
    link: "/app/patient-notifications",
    icon: <NotificationOutlined />,
  },
  // {
  //   id: 3,
  //   label: "Patients Appointment",
  //   link: "/app/patient-appointments",
  //   icon: <UnorderedListOutlined />,
  // },
  {
    id: 4,
    label: "Organizations",
    link: "/app/organizations",
    icon: <UnorderedListOutlined />,
  },
  {
    id: 5,
    label: "Sub Masters",
    link: "/app/sub-masters",
    icon: <UnorderedListOutlined />,
  },
  {
    id: 6,
    label: "Prime Health",
    link: "/app/prime-health",
    icon: <UnorderedListOutlined />,
  },
  {
    id: 7,
    label: "Google My Business",
    link: "/app/google-my-business",
    icon: <UnorderedListOutlined />,
  },
  {
    id: 8,
    label: "ER Directory",
    link: "/app/er-directory",
    icon: <UnorderedListOutlined />,
  },
  {
    id: 9,
    label: "Common Configuration",
    link: "/app/common-configuration",
    icon: <SettingOutlined />,
  },
  {
    id: 10,
    label: "User Management",
    link: "/app/user-management",
    icon: <UserOutlined />,
  },
  {
    id: 11,
    label: "Patients Notifications",
    link: "/app/patient-notifications",
    icon: <NotificationOutlined />,
  }
];

if(process.env.NODE_ENV === "production") {
  MENU.push(  {
    id: 12,
    label: "Data Search",
    link: "/app/data-search",
    icon: <UnorderedListOutlined />,
  });
}

export const masterCodes = [
  {
    key: "location",
    value: "Location",
  },
  {
    key: "speciality",
    value: "Speciality",
  },
  {
    key: "conditions",
    value: "Conditions",
  },
];

export const INR = "₹";

export const userGroups = {
  AllUsers: "allUsers",
  PX: "px",
  provider: "doctor", // -> keys are organizationIds
  // caregiver: "caregiver", // -> keys are userIds
  // team: "team",
  // doctor: "doctor", // keys are organizationIds
  // accountManager: "accountManager", // -> keys are organizationIds
  officeStaff: "officeStaff", // -> keys are organizationIds
  // medicalAssistant: "medicalAssistant", // -> keys are organizationIds
  officeAdmin: "officeAdmin",
  pmpAdministrator: "administrator", // -> keys are organizationIds
  superAdmin: "superAdmin",
  ERAdmin: "erAdmin",
  NursingStaff: "nursingStaff",
};
export const organizationType = {
  MedicalGroup: "medicalGroup",
  Hospital: "hospital",
  EmergencyRoom: "emergencyRoom",
  MedSpa: "medSpa",
  UrgentCare: "urgentCare",
  EHP: "EHP",
  ASC: "ASC",
};

export const organizationRegion = {
  "Region I - California": "Region I - California",
  "Region I - Nevada": "Region I - Nevada",
  "Region II - New Jersey": "Region II - New Jersey",
  "Region II - Pennsylvania": "Region II - Pennsylvania",
  "Region II - Rhode Island": "Region II - Rhode Island",
  "Region III - Alabama": "Region III - Alabama",
  "Region III - Georgia": "Region III - Georgia",
  "Region III - Texas": "Region III - Texas",
  "Region IV - Kansas": "Region IV - Kansas",
  "Region IV - Missouri": "Region IV - Missouri",
  "Region V - Michigan": "Region V - Michigan",
  "Region VI - Florida": "Region VI - Florida",
  "Region VI - Indiana": "Region VI - Indiana",
  "Region VI - Ohio": "Region VI - Ohio",
};

export const typeObj = {
  activeUser: "active",
  deactivate: "deactivate",
};
export const organizationStatusObj = {
  active: "active",
  deactivate: "deactivate",
};

export const userGlobalRoles = {
  // admin: "admin",
  // administrator: "administrator",
  // beta: "beta",
  // careManager: "careManager",
  // "grade-call-notes": "grade-call-notes",
  // "manage-prevention-questions": "manage-prevention-questions",
  // provider: "provider",
  // sysAdmin: "sysAdmin",
  providerAdmin: "providerAdmin",
};
export const TOKEN_RESTRICTED_PATH = ["chatwoot-frame", "chatthread-frame"];
