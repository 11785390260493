const variables = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  NODE_ENV: process.env.REACT_APP_NODE_ENV,
  GOOGLE_MY_BUSINESS: "https://mybusinessbusinessinformation.googleapis.com/v1",
  GOOGLE_MY_BUSINESS_ACCOUNTID: "104086650069834702393",
  CLIENT_ID:
    "788033178265-n2ffngeena8a48qegt8fhi8bu5k5edgh.apps.googleusercontent.com",
  APPV2_URL: process.env.REACT_APP_APPV2_URL,
  REDIRECT_URL: "http://localhost:3000/",
  GRANT_TYPE: "refresh_token",
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  PROVIDER_SYNC_URL: process.env.REACT_APP_PROVIDER_SYNC_URL,
  ER_DIRECTORY_URL:
    process.env.REACT_APP_ER_DIRECTORY_URL ||
    "https://er-server.dev.steerhealth.io",
  STEER_SERVICES_URL: process.env.REACT_APP_STEER_SERVICES_URL,
};

export default variables;
